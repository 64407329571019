import bg from '@/assets/purchaseOrder/bg.png'
import Process from './components/Process.vue'
import moment from "moment";
import {
    // 销售出库订单列表查询
    getOrderOutList,
    // 获取出库表格信息
    getTableList,
    // 订单出库
    saleOrderOut,
} from './api'

moment.locale("zh-cn");
export default {
    name: "channelWarehouse",
    components: {
        Process
    },
    data() {
        return {
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/channelReturns/agentWarehousingList",
                    name: "",
                    title: "代理商退货入库列表"
                },
                {
                    path: "",
                    name: "",
                    title: "退货入库详情"
                }
            ],
            bg: bg,
            //表格搜索
            tableSearch: '',
            columns: [{
                    title: '仓库',
                    dataIndex: 'name',
                    wth: 230,
                    ellipsis: true
                },
                {
                    title: '质量等级',
                    dataIndex: 'quvlitylv',
                    width: 150,
                },
                {
                    title: '补差类型',
                    dataIndex: 'invStatusTypeName',
                    width: 150
                },
                
                {
                    title: '已入库数量',
                    dataIndex: 'shippedBqty',
                    width: 150,
                },
                {
                    title: '正品退货数量',
                    dataIndex: 'numberThis',
                    width:166,
                    scopedSlots: {
                        customRender: "count"
                    }
                },
                {
                    title: '残次退数量',
                    dataIndex: 'numberThis1',
                    scopedSlots: {
                        customRender: "count1"
                    }
                },
            ],
            
           
            // 仅查看已选
            onlyShowChecked: false,
            sendObject: [],
            // 基本信息数据
            orderDetail: {
                orderHeader: {}
            },
            // 接口数据备份
            NeworderDetail: {
                NeworderHeader: {}
            },
            selectedRows: [],
            chukuTotal: 0,
            chukuVolume: 0,
            canciNum:0,
            daichuku: 0,
            // 全选list
            selectedRowKeys: [],
            selectCK: [],
            // 搜索用
            orgCode: '',
            searchLength: 0,
            model: '',
            pageLoadFlag: true,
            isLoading: false,
            buchais: false
        };
        
    },
  
    mounted() {
        // 销售出库列表查询
        this.getOrderList();
        // 获取出库表格信息
        // this.getTable()
    },
    methods: {
        // 销售出库列表查询
        // userAccount: this.$store.state.user.userInfo.account.account,
        getOrderList() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                returnOrderId: this.$route.query.id

            }

            getOrderOutList(data).then(res => {
                var realData = JSON.parse(res.data);
               
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    this.$router.go(-1);
                }
                var dataList = realData.data.itemList;
                this.orgCode = realData.data.orgCode;

                var newArray = [];
                for (var i = 0; i < dataList.length; i++) {
                    let datas = {};
                    this.daichuku = this.daichuku + Number(dataList[i].waitStockBQty);
                    //  分销直采库存查询条件
                    if (realData.data.documentType == 'normal' && realData.data.purchaseNum != '') {
                        datas = {
                            // 修改传参格式
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            warehouseType:70,
                            orgId:realData.data.orgId,
                           
                        }
                    } else {
                        datas = {
                            // 修改传参格式
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            warehouseType:70,
                            orgId:realData.data.orgId,
                        }
                    }
                    this.pageLoadFlag = false;
                    // this.getTable(data, i)
                    // 查询商品库存
                    newArray.push(getTableList(datas))
                };
                Promise.all(newArray).then(value => {
                 
                    for (let i = 0; i < value.length; i++) {
                        // var newArrays = [];
                        // 如果是gic预留  则可出库数量 == 预留数量                                   
                        // 非 gic预留仓库
                        // 本次出库数量需要 小于 可出库数量 - 已出库数量
                        var kechuku = 0;
                      
                        if (value[i].data) {
                            var realValue = JSON.parse(value[i].data);
                            realData.data.itemList[i].checkAll = false;
                            realData.data.itemList[i].selectedRowKeys = [];
                            realData.data.itemList[i].selectCK = []
                            realData.data.itemList[i].bencichuku = 0;
                            realData.data.itemList[i].cancituiKu = 0;
                            for (let y = 0; y < realValue.data.length; y++) {
                                realValue.data[y].index = i;
                                realValue.data[y].price = realData.data.itemList[i].acknowledgedAmount;
                                
                                realValue.data[y].key = y;
                                realValue.data[y].selectedTableRow = false;
                               
                                kechuku = kechuku + realValue.data[y].bigQty;
                                realValue.data[y].numberThis = 0;
                                realValue.data[y].numberThis1 = 0;
                                realValue.data[y].price = realData.data.itemList[i].bprice;
                                realValue.data[y].quvlitylv = realData.data.itemList[i].invStatusName;
                                realValue.data[y].bigQty = realData.data.itemList[i].waitStockBQty;
                                realValue.data[y].invStatusTypeName = realData.data.itemList[i].invStatusTypeName
                                realValue.data[y].shippedBqty = realData.data.itemList[i].shippedBqty
                                realValue.data[y].volume = realData.data.itemList[i].volume
                            }
                            let tableListGroup = []
                            realValue.data.forEach((item,index)=>{
                                // 之前是只展示第一条 现在是全都显示
                                tableListGroup.push(item)
                                // if(index == 0){
                                //     tableListGroup.push(item)
                                // }
                                return  tableListGroup
                            })
                            realData.data.itemList[i].searchLength = tableListGroup.length;
                            realData.data.itemList[i].tableList = tableListGroup;
                            realData.data.itemList[i].kechuku = kechuku;
                        }
                    }
                    this.orderDetail.orderHeader = realData.data;
                    this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));
                   
                    this.$forceUpdate();
                })
            }).finally(() => {
                this.pageLoadFlag = false;
            })
        },
        
        // 搜索仓库
        tableSearchClick(item, index) {

            var newarray = [];
            for (let item of this.orderDetail.orderHeader.itemList[index].tableList) {
               
                var indexok = item.name.indexOf(this.model)
                if (indexok != -1) {
                    newarray.push(item);
                } 
            }

            let tota = 0
            for (var i = 0; i < newarray.length; i++) {
                tota += Number(newarray[i].bigQty)
            }
          
            this.orderDetail.orderHeader.itemList[index].kechuku = tota;
            this.orderDetail.orderHeader.itemList[index].searchLength = newarray.length;
            this.orderDetail.orderHeader.itemList[index].tableList = newarray;
        },
        // 搜索框清空
        handleinput(index, e) {

            let tota = 0
            this.orderDetail.orderHeader.itemList[index].tableList = this.NeworderDetail.NeworderHeader.itemList[index].tableList;
            this.orderDetail.orderHeader.itemList[index].searchLength = this.NeworderDetail.NeworderHeader.itemList[index].tableList.length;
            for (var i = 0; i < this.NeworderDetail.NeworderHeader.itemList[index].tableList.length; i++) {
                tota += Number(this.NeworderDetail.NeworderHeader.itemList[index].tableList[i].bigQty)
            }
            this.orderDetail.orderHeader.itemList[index].kechuku = tota;
        },
        // 清空选择
        clearChose(index) {
  
            this.selectedRowKeys = [];
            this.selectCK = []
            this.orderDetail.orderHeader.itemList[index].checkAll = false;
            this.orderDetail.orderHeader.itemList[index].selectedRowKeys = [];
            this.orderDetail.orderHeader.itemList[index].selectCK = []
            this.sendObject[index] = [];
            var goodsNum = 0,
                goodsVol = 0;
            for (var i = 0; i < this.sendObject.length; i++) {
                for (var y = 0; y < this.sendObject[i].length; y++) {
                    goodsNum = goodsNum + Number(this.sendObject[i][y].numberThis);
                    goodsVol = goodsVol + Number(this.sendObject[i][y].volume);
                }
            }
            this.chukuTotal = goodsNum;
            this.chukuVolume = goodsVol;
        },
 
        onSelect(record, selected, selectedRows) {

            record.selectedTableRow = selected;
            var totalIndex = record.index;
  
            this.sendObject[totalIndex] = selectedRows;
  
            if (!selected) {
                this.orderDetail.orderHeader.itemList[totalIndex].bencichuku = this.orderDetail.orderHeader.itemList[totalIndex].bencichuku - record.numberThis;
                this.orderDetail.orderHeader.itemList[totalIndex].cancituiKu = this.orderDetail.orderHeader.itemList[totalIndex].cancituiKu - record.numberThis;
                record.numberThis = 0;
            }
            var newarray = [];
            for (let i = 0; i < selectedRows.length; i++) {
                newarray.push(selectedRows[i].key);
            }
            this.orderDetail.orderHeader.itemList[totalIndex].selectedRowKeys = newarray;
              //  获取到选择的仓库id
              var selectCkInfo = [];
              this.orderDetail.orderHeader.itemList[totalIndex].tableList.forEach(item=>{
                 if( item.selectedTableRow ) {
                    selectCkInfo.push(item.cId);
                 }
              })
              // 选择的仓库push到数组
              this.orderDetail.orderHeader.itemList[totalIndex].selectCK = selectCkInfo;
            // 计算选中商品 体积 数量
            var goodsnum = 0,
                goodsvol = 0;
            for (var y = 0; y < this.sendObject.length; y++) {
               
                if (this.sendObject[y]) {
                    for (var z = 0; z < this.sendObject[y].length; z++) {
                        goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                        goodsvol =  Number(this.sendObject[y][z].volume * goodsnum)
                    }
                }
            }

            this.chukuTotal = goodsnum;
            this.chukuVolume = this.$util.getFloat(goodsvol,3);
        },
        // 表格全选
        onSelectAll(selected, selectedRows, changeRows) {
          
            var newarray = [];
            var selectCKInfo = [];
            var chosenumber = 0;
            var totalIndex = changeRows[0].index;
            this.sendObject[totalIndex] = selectedRows;
            if (selected) {
                for (let i = 0; i < changeRows.length; i++) {
                    newarray.push(changeRows[i].key)
                    selectCKInfo.push(changeRows[i].cId)
                    changeRows[i].selectedTableRow = selected;
                }
                this.orderDetail.orderHeader.itemList[totalIndex].selectedRowKeys = newarray;
                this.orderDetail.orderHeader.itemList[totalIndex].selectCK = selectCKInfo;
            } else {
                for (let i = 0; i < changeRows.length; i++) {
                    chosenumber += Number(changeRows[i].numberThis)
                    changeRows[i].numberThis = 0;
                    changeRows[i].numberThis1 = 0;
                    changeRows[i].selectedTableRow = selected;
                    this.orderDetail.orderHeader.itemList[totalIndex].bencichuku = changeRows[i].numberThis+changeRows[i].numberThis;
                   
                    this.chukuVolume =  this.$util.getFloat(this.orderDetail.orderHeader.itemList[totalIndex].bencichuku * changeRows[i].volume,3) 
                    this.canciNum =  this.$util.getFloat(this.orderDetail.orderHeader.itemList[totalIndex].cancituiKu * changeRows[i].volume,3) 
                    this.orderDetail.orderHeader.itemList[totalIndex].cancituiKu = 0;
                }
                this.orderDetail.orderHeader.itemList[totalIndex].selectedRowKeys = newarray;
                this.orderDetail.orderHeader.itemList[totalIndex].selectCK = selectCKInfo;
                this.chukuTotal = this.chukuTotal - chosenumber;
               
            }

        },
        // 仅查看已选
        onlyShowCheckedChange(item, index, e) {
            if (e.target.checked) {
                this.orderDetail.orderHeader.itemList[index].tableList = this.sendObject[index];
            } else {
                this.orderDetail.orderHeader.itemList[index].tableList = this.NeworderDetail.NeworderHeader.itemList[index].tableList;
            }
            this.orderDetail.orderHeader.itemList[index].checkAll = e.target.checked;
        },
        //出库数量改变
        inputNumberChange(row) {
            var realNumber = this.orderDetail.orderHeader.itemList[row.index];
            var realData = this.orderDetail.orderHeader.itemList;
            var chukuNumber = 0;
            for (let i = 0; i < realNumber.tableList.length; i++) {
                chukuNumber += Number(realNumber.tableList[i].numberThis);
            }
            this.orderDetail.orderHeader.itemList[row.index].bencichuku = chukuNumber;
            var chukuzong = 0,
                chukutiji = 0
            for (let y = 0; y < realData.length; y++) {
                if (realData[y].tableList.length) {
                    for (let z = 0; z < realData[y].tableList.length; z++) {
                        chukuzong += Number(realData[y].tableList[z].numberThis1) + Number(realData[y].tableList[z].numberThis);
                        chukutiji += Number(realData[y].tableList[z].numberThis1 * realData[y].tableList[z].volume) +  Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].volume);
                    }
                }
            }
            this.chukuTotal = chukuzong;
            this.chukuVolume = this.$util.getFloat(chukutiji, 3);
        },
        inputNumberChange1(row) {
            let realNumber = this.orderDetail.orderHeader.itemList[row.index];
            let realData = this.orderDetail.orderHeader.itemList;
            let chukuNumber = 0;
            for (let i = 0; i < realNumber.tableList.length; i++) {
                chukuNumber += Number(realNumber.tableList[i].numberThis1);
            }
            this.orderDetail.orderHeader.itemList[row.index].cancituiKu = chukuNumber;
            var chukuzong = 0,
                chukutiji = 0
            for (let y = 0; y < realData.length; y++) {
                if (realData[y].tableList.length) {
                    for (let z = 0; z < realData[y].tableList.length; z++) {
                        chukuzong += Number(realData[y].tableList[z].numberThis1) + Number(realData[y].tableList[z].numberThis);
                        chukutiji += Number(realData[y].tableList[z].numberThis1 * realData[y].tableList[z].volume) +  Number(realData[y].tableList[z].numberThis * realData[y].tableList[z].volume);
                    }
                }
            }
            this.chukuTotal = chukuzong;
            this.chukuVolume = this.$util.getFloat(chukutiji, 3);
        },
        //提交
        orderSubmit() {
            if (this.daichuku < this.chukuTotal) {
                this.$message.warning('入库数量不得大于待入库数量');
                return;
            }
            var realData = this.sendObject;
            if (realData.length == 0) {
                this.$message.warning('请先选择仓库');
                return;
            } else {
                var submitArray = [];
                var realValue = this.orderDetail.orderHeader.itemList
                var isFinished = true;
                for (let i = 0; i < realData.length; i++) {
                    var submitData = {},
                        outThis = 0;
                        let cancituiNum = 0
                    if (realData[i]) {
                        for (let z = 0; z < realData[i].length; z++) {
                            if ((realData[i][z].numberThis + realData[i][z].numberThis1 )== 0) {
                                this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0')
                                return;
                            }
                           
                            if (realValue[i].bencichuku < realValue[i].waitStockBQty) {
                                isFinished = false;
                            }
                            outThis = outThis + realData[i][z].numberThis;
                            cancituiNum = cancituiNum + realData[i][z].numberThis1
                            submitData = {
                                model:realValue[i].model,
                                productCode:realValue[i].productCode,
                                productName:realValue[i].productName,
                                colour:realValue[i].colour,
                                bprice: realValue[i].bprice,
                                borderedQty:Number(realValue[i].borderedQty),
                                ordinaryQty:Number(outThis),
                                imperfectQty:Number(cancituiNum),
                                shippedBqty:Number(realValue[i].shippedBqty),
                                invStatusId:realValue[i].invStatusId,
                                warehouseId:(realValue[i].selectCK).toString(),
                                materialCode:realValue[i].materialCode,
                                materialGroupCode:realValue[i].materialGroupCode,
                            }
                            submitArray.push(submitData);
                        }
                        if (outThis > realValue[i].receivedotyNew) {
                            this.$message.warning('第' + Number(i + 1) + '个商品入库数量大于商品该入库数量')
                            return;
                        }
                    }
                }
                if (submitArray.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                }
                this.isLoading = true;
                var data = {
                    returnOrderId: this.$route.query.id,
                    cisCode: this.$store.state.user.userInfo.customer.cisCode,
                    returnItemList: submitArray
                }
                
                saleOrderOut(data).then(res => {
                    this.isLoading = false;
                    var realData = JSON.parse(res.data);
                    if (realData.code == '1') {
                        this.$message.warning(realData.msg);
                    } else if (realData.code == '0') {
                        this.$message.success('入库成功')
                        setTimeout(function(){
                            localStorage.setItem('XTW', new Date())   
                            // 关闭窗口之前刷新父页面
                            window.opener.location.reload();
                            window.close()
                            this.$router.push({ path:'/channelReturns/agentWarehousingList'});
                        }, 1000);
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }

        },

        // 取消
        orderCancle() {
            localStorage.setItem('XTW', new Date());
            window.opener.location.reload();
            window.close()
            
        },
        // 
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
    },
};